import styled from 'styled-components';
import { Checkbox } from 'antd';

export const CustomCheckbox = styled(Checkbox)`
  &:hover {
    .ant-checkbox-checked:after {
      border-radius: 5px;
    }
  }
`;

export const SingleContactRow = styled.div`
  margin-bottom: 2px;
  .action {
    display: none;
  }
  &:hover {
    .action {
      display: block;
    }
  }
  &.active {
    background: #f5f2f8;
    .more-tags {
      border: 1px solid #cfccd5;
      &:focus {
        border: 1px solid #cfccd5;
      }
    }
  }
  .ant-checkbox {
    top: 0;
  }
  .ant-dropdown-menu {
    padding: 5px 0;
    .ant-dropdown-menu-item {
      padding: 5px 10px;
    }
  }
`;
export const ContactContainer = styled.div`
  height: calc(100% - 63px); // parent container height - header height
  > div > div {
    overflow-y: scroll !important;
  }
`;

export const EmptyContact = styled.div`
  height: calc(100% - 65px); // minus topbar height;
`;

export const SkeletonContainer = styled.div`
  .ant-skeleton {
    .ant-skeleton-avatar {
      border-radius: 10px;
    }
    &-content {
      display: flex;
      gap: 16px;
      align-items: center;
      height: 32px;
    }
    &-paragraph {
      width: 50%;
    }
    &-title,
    &-paragraph {
      margin: 0 !important;
    }
  }
  .ant-divider-horizontal {
    margin: 19px 0;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  &.outer {
    width: 100%;
    gap: 16px;
    overflow-x: scroll;
  }
`;
