import { useRef, useState, useEffect } from 'react';
import { useReactiveVar, useLazyQuery, useSubscription } from '@apollo/client';
import clonedeep from 'lodash.clonedeep';
import { datadogLogs } from '@datadog/browser-logs';

import { useTwilioContext } from 'lib/twilio';
import { NUMBER_SETTINGS } from 'graphql/channel/settings';
import { COMMUNICATION_SUBSCRIPTION } from 'graphql/channel/conversation';
import {
  activeAudioInputId,
  activeAudioOutputId,
  activeCallConversationData,
  activeCoachingLiveCallData,
  clientInfoCallWidgetVar,
} from 'services/apollo/reactiveVars';
import { useFeatureByPlan, featureList } from 'hooks/useFeatureByPlan';
import useClientNote from 'components/organisms/contact/detail/hooks/useClientNote';
import { RecordAction } from 'generated/graphql';
import { ACTIONS, CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { getCallParams } from 'lib/twilio/utils';
import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import { ToastMessage, Icon } from 'components/atoms';
import { useCampaignHandlers } from 'components/pages/sales-dialer/campaigns/hooks/useCampaignHandlers';
import { useCoaching } from 'components/pages/dashboard/call-stats/table/useCoaching';

import { usePhoneMutation } from './usePhoneMutation';
import { useRecordingTimer } from './useRecordingTimer';
import { useCallDurationTimer } from './useCallDurationTimer';
import { useClientQuery } from './useClientQuery';
import { usePhoneQuery } from './usePhoneQuery';
import { useCallTransfer } from './useCallTransferHandler';
import useCampaignNoteMutation from './useCampaignNoteMutation';
import { BannerType } from '../types';
import useCallWidgetContext from './useCallWidgetContext';
import useCallWidgetDrawerContext from './useCallWidgetDrawerContext';
import { ERROR_MESSAGES } from '../constants';

export function useActiveCallWidgetHandler() {
  const { onSaveChangeSuccess } = useCallWidgetDrawerContext();
  const { setBanner, showBannerLikeToast } = useCallWidgetContext();
  const [keypadNumberInput, setKeypadNumberInput] = useState('');
  const [note, setNote] = useState<Record<string, any>>({ title: '' });
  const [mute, setMute] = useState<boolean>(false);
  const [recording, setRecording] = useState<boolean>(false);
  const [hold, setHold] = useState<boolean>(false);
  const [recordingTimeSec, setRecordingTimeSec] = useState<number>(0);
  const [recordingTimeMin, setRecordingTimeMin] = useState<number>(0);
  const [hasCallRecordAccess] = useFeatureByPlan(featureList['call-recordings-and-storage']);
  const [selectedMoreOption, setSelectedMoreOption] = useState<null | string>();
  const [campaignCallNote, setCampaignCallNote] = useState<Record<string, any>>({ title: '' });
  const [isSpeakerMute, setIsSpeakerMute] = useState<boolean>(false);

  const { ANSWERED, ENDED, TRANSFERRING, TRANSFER_FAILED } = CALL_WIDGET_STATUS;

  const activeCoachLiveCallData = useReactiveVar(activeCoachingLiveCallData);
  const { handleLeaveCoaching } = useCoaching({ record: activeCoachLiveCallData });
  const conversationData = useReactiveVar(activeCallConversationData);
  const activeMic = useReactiveVar(activeAudioInputId);
  const activeSpeaker = useReactiveVar(activeAudioOutputId);
  const clientInfoCallWidgetData = useReactiveVar(clientInfoCallWidgetVar);

  const activeMicRef: any = useRef();
  activeMicRef.current = activeMic;
  const activeSpeakerRef: any = useRef();
  activeSpeakerRef.current = activeSpeaker;
  const conversationDataRef: any = useRef();
  conversationDataRef.current = conversationData;
  const clientInfoCallWidgetDataRef: any = useRef();
  clientInfoCallWidgetDataRef.current = clientInfoCallWidgetData;

  const {
    deviceInstance,
    dispatch,
    state: {
      direction,
      connection,
      callInProgress,
      status = '',
      channelId,
      liveCall,
      salesDialerWidget,
    },
  } = useTwilioContext();
  const { handleCampaignRun } = useCampaignHandlers();
  const { audioInputDevices } = useMediaDevices();
  const isTransferredCall = connection?.customParameters?.get('after_transfer') === 'True';
  const campaignRecordingEnabled = connection?.customParameters?.get('recordingEnabled') === 'True';
  const isCampaignCall = !!salesDialerWidget;

  const contactNumber =
    direction === 'Incoming'
      ? `+${connection?.customParameters?.get('from')?.trim()?.replace(/\+/g, '')}`
      : connection?.customParameters?.get('To') || '';

  const channelInfo = usePhoneQuery({ channelId });

  const { data: subscriptionData } = useSubscription(COMMUNICATION_SUBSCRIPTION, {
    variables: {
      channel: channelId,
    },
    onSubscriptionData: (arg: any) => {
      const newMessage = arg?.subscriptionData?.data?.updateConversation?.message;
      const conversationStatus = newMessage?.conversationStatus;
      if (!newMessage) return;
      if (process.env.REACT_APP_DEBUG)
        console.log('[Subs updateConversation triggered call widget handler]:', newMessage);
      if (!conversationDataRef.current && newMessage?.conversationType === 'Call') {
        activeCallConversationData(newMessage);
      }
    },
  });

  useEffect(() => {
    return () => {
      activeCallConversationData(null);
    };
  }, []);

  const { clientInfo } = useClientQuery({
    number: contactNumber,
  });

  useEffect(() => {
    if (clientInfo) {
      clientInfoCallWidgetVar(clientInfo);
    }
    return () => {
      clientInfoCallWidgetVar({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo?.number]);

  const { callDurationTime, resetCallDurationTime } = useCallDurationTimer({
    startTimer: [ANSWERED, ENDED, TRANSFERRING, TRANSFER_FAILED].includes(status),
    stopTimer: status === ENDED,
  });

  const { recordingTime, seconds, minutes, recorded, resetRecordedTime } = useRecordingTimer({
    recordingTimeSec,
    recordingTimeMin,
    startTimer: recording && callInProgress,
  });

  const [loadNumberSettings, { data: numbersData }] = useLazyQuery(NUMBER_SETTINGS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (channelId) {
      loadNumberSettings({
        variables: {
          channel: channelId,
        },
      });
    }
  }, [channelId, loadNumberSettings]);

  const autoRecordingEnabled = !isCampaignCall
    ? numbersData?.numberSettings?.data?.autoRecordCalls
    : campaignRecordingEnabled;

  useEffect(() => {
    if (autoRecordingEnabled && hasCallRecordAccess && !isTransferredCall) {
      setRecording(true);
      setRecordingTimeSec(seconds);
      setRecordingTimeMin(minutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRecordingEnabled, isTransferredCall]);

  const onCallRecordingSuccess = () => {
    setRecording(!recording);
  };

  const onCallHoldSuccess = () => {
    setHold(!hold);
  };

  // HANDLE SUCCESS FOR CAMPAIGN CALL SKIP
  const onCallSkipSuccess = (nextId: string, queuePhoneNumber: string) => {
    const customParametersMap = connection?.customParameters;
    customParametersMap?.set('nextQueue', queuePhoneNumber);
    customParametersMap?.set('nextCampaignConversationId', nextId);
    const newConnection = clonedeep(connection);
    (connection as any).customParameters = customParametersMap;
    dispatch({
      type: ACTIONS.CAMPAIGN_CALL_SKIPPED,
      data: {
        connection: newConnection,
        nextCallQueue: {
          id: nextId,
          number: queuePhoneNumber,
        },
      },
    });
  };

  const onDropVoicemailSuccess = () => {
    setBanner?.({
      title: 'Voicemail dropped ',
      closable: true,
      type: BannerType.info,
      showIcon: true,
      icon: <Icon name='voicemail' />,
    });
    setTimeout(() => {
      setBanner?.({});
    }, 3000);
  };

  const {
    updateCallRecording,
    updateCallHold,
    loadingCallHold,
    loadingCallRecord,
    updateRejectConversation,
    skipCampaignCall,
    dropVoicemail,
  } = usePhoneMutation({
    recording,
    onCallRecordingSuccess,
    onCallHoldSuccess,
    onCallSkipSuccess,
    onDropVoicemailSuccess,
  });

  const { callTransfer, loadingCallTransfer } = useCallTransfer();

  const onNoteSave = (data: any) => {
    setNote(data);
    setSelectedMoreOption(null);
    onSaveChangeSuccess();
    showBannerLikeToast?.('Notes added.', BannerType.info);
  };
  const onNoteSaveError = (message?: string) => {
    showBannerLikeToast?.(message ?? '', BannerType.error);
  };

  const onCampaignNoteSave = (data: any) => {
    setCampaignCallNote(data);
    onSaveChangeSuccess();
    showBannerLikeToast?.('Notes added.', BannerType.info);
  };

  const onCampaignNoteSaveError = () => {
    showBannerLikeToast?.('Something went wrong.', BannerType.error);
  };

  const { addContactNote, editContactNote, loadingSaveNote } = useClientNote({
    onNoteSave,
    onNoteSaveError,
  });
  const {
    createCampaignCallNote,
    editCampaignCallNote,
    loadingCampaignSaveNote,
  } = useCampaignNoteMutation({
    onNoteSave: onCampaignNoteSave,
    onNoteSaveError: onCampaignNoteSaveError,
  });

  // CALL ACCEPT
  const handleCallAnswer = (e: React.MouseEvent) => {
    e.preventDefault();
    if (audioInputDevices?.length < 1) {
      ToastMessage({
        content: `${ERROR_MESSAGES.noMicPermission}`,
        type: 'danger',
      });
      return;
    }
    if (liveCall?.params) {
      handleLeaveCoaching();
    }
    if (!deviceInstance?.audio?.isOutputSelectionSupported) {
      connection?.accept();
      return;
    }
    deviceInstance?.audio
      ?.setInputDevice(activeMicRef.current || 'default')
      .then(() => {
        connection?.accept();
      })
      .catch(error => {
        connection?.accept();
        console.error(error);
        datadogLogs.logger.warn('Audio Input Device Error', {
          datadogError: { ...error, errorMessage: error?.message },
          additionalInfo: {
            activeMic: activeMicRef.current,
            availableInputDevices: audioInputDevices,
            callParams: getCallParams(connection),
          },
          context: 'call',
        });
      });
    deviceInstance?.audio?.speakerDevices
      ?.set(activeSpeakerRef.current || 'default')
      .catch(error => {
        console.error(error);
        datadogLogs.logger.warn('Audio Output Device Error', {
          datadogError: { ...error, errorMessage: error?.message },
          additionalInfo: {
            activeSpeaker: activeSpeakerRef.current,
          },
          context: 'call',
        });
      });
  };

  // CALL REJECT
  const handleCallReject = (e: React.MouseEvent) => {
    e.preventDefault();
    if (direction !== 'Incoming') {
      connection?.disconnect();
      return;
    }
    if (callInProgress) {
      connection?.disconnect();
    } else {
      connection?.reject();
      if (conversationDataRef.current?.id) updateRejectConversation(conversationDataRef.current.id);
    }
  };

  // CALL RECORDING
  const handleToggleRecording = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const recordingStartVar = recorded ? RecordAction.Resume : RecordAction.Start;
    const recordingPauseVar = RecordAction.Pause;
    const campaignConversationId = connection?.customParameters?.get('campaignConversationId');
    const conversationSid = connection?.customParameters?.get('conversation_sid');
    const data = {
      action: recording ? recordingPauseVar : recordingStartVar,
      isCampaignCall,
      campaignConversationId,
      conversationSid,
    };
    await updateCallRecording(data);
  };

  // CALL HOLD
  const handleToggleHold = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const campaignConversationId = connection?.customParameters?.get('campaignConversationId');
    const conversationSid = connection?.customParameters?.get('conversation_sid');
    const data = {
      hold,
      channelId,
      isCampaignCall,
      campaignConversationId,
      conversationSid,
    };
    await updateCallHold(data);
  };

  // CALL MUTE
  const handleToggleMute = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    connection?.mute(!mute);
    setMute(!mute);
  };

  //  CALL SPEAKER ON/OFF
  const handleToggleMuteSpeaker = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    connection
      ?.getRemoteStream()
      ?.getAudioTracks()
      ?.forEach((track: MediaStreamTrack) => {
        // eslint-disable-next-line no-param-reassign
        track.enabled = isSpeakerMute;
      });
    setIsSpeakerMute(!isSpeakerMute);
  };

  // KEYPAD DIAL
  const handleDialKeypad = (number: string) => {
    setKeypadNumberInput(keypadNumberInput + number);
    connection?.sendDigits?.(number);
  };

  // CLEAR TRAILING KEYPAD NUMBER
  const removeTrailingKeypadNumber = () => {
    setKeypadNumberInput(keypadNumberInput?.slice(0, -1));
  };

  // CLEAR KEYPAD NPUT INUMBER
  const clearKeypadInput = () => {
    setKeypadNumberInput('');
  };

  // NOTE SAVE
  const handleNoteSave = (title?: string) => {
    let payload;
    if (note?.id) {
      payload = {
        id: note?.id,
        data: {
          title,
        },
      };
      editContactNote(payload);
      return;
    }

    const conversationStringId = connection?.customParameters?.get('conversation_sid');
    payload = {
      conversationStringId,
      contact: clientInfo?.number,
      data: {
        title,
        channelId: channelInfo?.id,
      },
    };
    addContactNote(payload);
  };

  // CALL TRANSFER
  const handleCallTransfer = (data: any, to: string) => {
    const conversationSid = connection?.customParameters?.get('conversation_sid');
    const payload: any = {
      ...data,
      by_sid: true,
      conversation_sid: conversationSid,
    };
    callTransfer(payload, to);
  };

  // MORE ACTION
  const handleSelectMoreAction = (option?: string) => {
    setSelectedMoreOption(option);
  };

  // SALES DIALER CAMPAIGN ACTIONS
  const handleExecuteCampaignAction = (id: string, action: string) => {
    handleCampaignRun(id, action);
  };

  // CALL CAMPAIGN NOTE SAVE
  const handleCampaignNoteSave = (payload: any, action = 'create') => {
    if (action === 'create') {
      createCampaignCallNote(payload);
      return;
    }
    editCampaignCallNote(payload);
  };

  // SKIP CAMPAIGN CALL QUEUE
  const handleSkipCampaignCallQueue = (conversationId: string, campaignId: string) => {
    skipCampaignCall(conversationId, campaignId);
  };

  // DROP VOICEMAIL FROM SAILES DIALER WIDGET
  const handleDropVoicemail = (conversationId: string, campaignId: string) => {
    dropVoicemail(conversationId, campaignId);
  };

  // CLEAR CAMPAIGN NOTE
  const handleClearCampaignNote = () => {
    setCampaignCallNote({});
  };

  const handleResetCallWidget = () => {
    setNote({ title: '' });
    setMute(false);
    setIsSpeakerMute(false);
    setHold(false);
    resetCallDurationTime();
    setRecording(autoRecordingEnabled);
    resetRecordedTime();
  };

  return {
    callDurationTime,
    mute,
    isSpeakerMute,
    recording,
    recordingTime,
    loadingCallRecord,
    autoRecordingEnabled,
    hold,
    loadingCallHold,
    clientInfo,
    channelInfo,
    keypadNumberInput,
    note,
    loadingSaveNote,
    loadingCallTransfer,
    selectedMoreOption,
    loadingCampaignSaveNote,
    campaignCallNote,
    handleCallAnswer,
    handleCallReject,
    handleToggleRecording,
    handleToggleHold,
    handleToggleMute,
    handleToggleMuteSpeaker,
    handleDialKeypad,
    handleNoteSave,
    handleCallTransfer,
    handleSelectMoreAction,
    handleExecuteCampaignAction,
    handleCampaignNoteSave,
    handleSkipCampaignCallQueue,
    handleResetCallWidget,
    handleDropVoicemail,
    removeTrailingKeypadNumber,
    handleClearCampaignNote,
    clearKeypadInput,
  };
}
