import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import cache from 'services/apollo/cache';
import { ToastMessage } from 'components/atoms';
import { RESTORE_WORKSPACE } from 'graphql/foundation';
import useAuthContext from 'hooks/useAuthContext';

export function useRestoreWorkspace(props?: any) {
  const { t } = useTranslation();
  const { activeWorkspaceId } = useAuthContext();

  const [restoreWorkspace] = useMutation(RESTORE_WORKSPACE, {
    onCompleted: response => {
      const { data } = response.restoreWorkspace;
      if (data.id) {
        ToastMessage({
          content: t('workspaceRestoreSuccess', 'Workspace restored.'),
          type: 'success',
        });
        props?.setVisibleRestoreModal?.(false);
        props?.setIsDeletedWorkspace?.(false);
        cache.modify({
          fields: {
            workspace: () => {},
            workspaces: () => {},
          },
        });
      }
    },
    onError: () =>
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' }),
  });

  const onRestore = async () => {
    await restoreWorkspace({
      variables: {
        id: activeWorkspaceId,
      },
    });
  };
  return {
    onRestore,
  };
}
