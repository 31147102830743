import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

import { TAG_NODE, TAG_RESPONSE_NODE, CONTACT_NODE, CLIENT } from './fragments';

export const ADD_NEW_CONTACT = gql`
  mutation addNewContact($data: ContactInputData!) {
    addNewContact(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        country
        company
        number
        address
        name
        visibility
        tags {
          ...TagFields
        }
        notes {
          id
          title
        }
        email
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TAG_NODE}
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($id: ShortId!, $data: EditContactInputData!) {
    updateContact(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        country
        name
        address
        company
        number
        visibility
        email
        tags {
          ...TagFields
        }
        notes {
          id
          title
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TAG_NODE}
`;

export const ADD_NOTE = gql`
  mutation addNoteByContact(
    $conversationStringId: String
    $contact: String!
    $data: NoteInputData!
  ) {
    addNoteByContact(conversationStringId: $conversationStringId, contact: $contact, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        title
        client {
          ...ClientFields
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CLIENT}
`;

export const EDIT_NOTE = gql`
  mutation editNote($id: ShortId!, $data: EditNoteInputData!) {
    editNote(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        title
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const REMOVE_NOTE = gql`
  mutation removeNote($id: ShortId!) {
    removeNote(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
        title
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ADD_TAG = gql`
  mutation addTag($data: TagInputData!) {
    addTag(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...TagResponseFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TAG_RESPONSE_NODE}
`;
export const REMOVE_TAG = gql`
  mutation removeTag($id: ShortId!) {
    removeTag(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const UNASSIGN_TAG_FROM_CONTACT = gql`
  mutation removeContactTag($id: ShortId!, $data: RemoveContactTagInputData!) {
    removeContactTag(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const EDIT_TAG = gql`
  mutation editTag($id: ShortId!, $data: EditTagInputData!) {
    editTag(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const BLOCK_OR_UNBLOCK_NUMBER = gql`
  mutation blockNumber($number: String!, $data: BlockNumberInputData!) {
    blockNumber(number: $number, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        blocked
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DELETE_CONTACTS = gql`
  mutation deleteContacts($data: DeleteContactsInputData!) {
    deleteContacts(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPLOAD_CSV_CONTACT = gql`
  mutation uploadContact($data: ContactCsvInputData!) {
    uploadContact(data: $data) {
      status
      data {
        totalRecords
        savedRecords
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_CLIENT_DND = gql`
  mutation updateClientDND($data: ClientDNDInput!) {
    updateClientDND(data: $data) {
      status
      data {
        ...ContactFields
      }
      error {
        ...Error
      }
    }
  }
  ${CONTACT_NODE}
  ${ERROR_FRAGMENT}
`;

export const UPLOAD_GREETINGS_MP3 = gql`
  mutation greetings($channel: ShortId!, $data: GreetingInputData!) {
    greetings(channel: $channel, data: $data) {
      status
      data {
        id
        recordingUrl
        greetingType
        recordingType
        source
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const TOGGLE_GREETINGS = gql`
  mutation greetingsToggle($channel: ShortId!, $data: GreetingToggleInputData!) {
    greetingsToggle(channel: $channel, data: $data) {
      status
      data {
        greetingType
        recordingType
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const AUTO_REPLY_TEXT = gql`
  mutation autoReply($data: AutoReplyInputData!) {
    autoReply(data: $data) {
      status
      data {
        id
        channelId
        createdBy
        message
        status
        autoreplyType
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const REMOVE_RECORDING_GREETINGS = gql`
  mutation removeCustomRecordingFromGreetings(
    $channel: ShortId!
    $data: GreetingCustomRecordingData!
  ) {
    removeCustomRecordingFromGreetings(channel: $channel, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
