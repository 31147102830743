import { makeVar } from '@apollo/client';

export const contactBlockedVar = makeVar(false);
export const activeAudioInputId = makeVar<string>('default');
export const activeAudioOutputId = makeVar<string>('default');
export const audioConstraintsVar = makeVar<any>({
  echoCancellation: false,
  noiseSuppression: false,
});
export const userOnlineStatus = makeVar<boolean>(true);
export const showWorkspaceDetailDrawer = makeVar<boolean>(false);
export const showCallboxContactDetail = makeVar<boolean>(false);
export const showCallboxContactLog = makeVar<boolean>(false);
export const showCallboxSearchDrawer = makeVar<boolean>(false);
export const showMemberChatSearchDrawer = makeVar<boolean>(false);
export const showMemberChatMemberDetail = makeVar<boolean>(true);

export const showUnseenMsgMarkVar = makeVar<any>({
  unseenCount: 0,
  messageId: '',
  messageCursor: '',
  showUnseenMark: false,
});
export const isNetworkAvailable = makeVar<boolean>(true);
export const loadingSwitchWorkspace = makeVar<boolean>(false);
export const defaultWorkspaceVar = makeVar<string | null>(null);
export const activeCallConversationData = makeVar<any>(null);
export const webRtcPeerConnectionEnabled = makeVar<boolean>(true);
export const activeCallCampaignStatus = makeVar<string | null>(null);
export const smsLimitExceededWarning = makeVar<boolean>(false);
export const fcmMessagingInstance = makeVar<any>(null);
export const wksUsecasesVar = makeVar<any>(null);
export const wksBusinessDetailVar = makeVar<any>(null);
export const campaignReattemptVar = makeVar<boolean>(false);
export const activeCallDurationVar = makeVar<number>(0);
export const activeCallTimerVar = makeVar<string>('');
export const activeCampaignDurationVar = makeVar<number>(0); // running campaign duration
export const activeCampaignCallsDurationVar = makeVar<number>(0); // sum of completed calls in campaign

export const transferToVar = makeVar<string>('');
export const helpScoutResponseVar = makeVar<any>(null);

export const isNumberPurchasedWks = makeVar<boolean>(true); // to check if at least one number is purchased in workspaace
export const activeCoachingLiveCallData = makeVar<Record<string, any> | undefined>(undefined);

export const clientInfoCallWidgetVar = makeVar<Record<string, any> | undefined>(undefined);
