import { matchPath } from 'react-router-dom';
import * as path from 'constants/routes';

export default function useRouteChecker({ pathname }: { pathname: string }) {
  const isRestorePage = matchPath(pathname, [path.WORKSPACE_RESTORE] as any);

  const isPublicPage = matchPath(pathname, [
    path.LOGIN,
    path.REGISTER,
    path.REGISTER_TEMPORARY,
    path.FORGOT_PASSWORD,
    path.RESET_PASSWORD,
    path.BOOK_DEMO,
    '/registration-verify-email',
    path.LOGIN_OTP_VERIFICATION,
  ] as any);
  const isMemberInvitePage = matchPath(pathname, [path.ACCEPT_MEMBER_INVITATION] as any);
  const isAuthPage = matchPath(pathname, [path.NEW_WORKSPACE, path.ACCOUNT_SETUP_GUIDE] as any);
  const isAppPage = matchPath(pathname, [
    path.DASHBOARD,
    path.MOBILE_APPS,
    path.SALES_DIALER,
    path.DOWNLOAD_APPS,
    path.SETTINGS,
    // TODO: BULK SMS disabled for release 2.14.4 for production server. Need to enable bulk sms in next release */
    path.BULK_SMS,
    path.WORKSPACE_VIEW,
    `${path.MEMBERS}/:memberId?`,
    `${path.CONTACTS}/:tags/tags`,
    `${path.CONTACTS}/:contactId?`,
    `${path.TEAM}/:teamId/:memberId?`,
    `${path.NUMBERS}/:channelId/:contactId?`,
    `${path.NON_VOIP_NUMBERS}/:channelId/:contactId?`,
  ] as any);
  const isSupportPage = matchPath(pathname, [
    path.PURCHASE,
    path.NON_VOIP_PURCHASE,
    path.SUPPORT,
    path.PORTING,
    path.PLAN,
    path.PLANSETUP,
    path.RESTORE_NUMBER,
    path.FEEDBACKS,
    path.WORKSPACES,
    path.CANCELLED_WORKSPACES,
    path.DELETED_WORKSPACES,
    path.USERS,
    path.AUTHORIZATION,
    path.SUPPORT_AUTHORIZATION,
    path.ACCOUNT_REVIEW,
    path.EMAIL_VERIFICATION,
    path.SETUP_APPS,
    path.INCOMPLETE_SIGNUP,
    path.COMPLETE_SIGNUP,
    path.WORKSPACE_CREDIT,
    path.WORKSPACE_SPAM,
    path.WORKSPACES_INACTIVE,
    path.AUDIT_LOG,
    path.NUMBER_RATES,
  ] as any);
  const isDialerPage = matchPath(pathname, [path.DIALER] as any);

  return {
    isAppPage,
    isAuthPage,
    isRestorePage,
    isSupportPage,
    isPublicPage,
    isDialerPage,
    isMemberInvitePage,
  };
}
