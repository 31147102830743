import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useReactiveVar } from '@apollo/client';

import Suspense from 'services/Suspense';
import { loadingSwitchWorkspace } from 'services/apollo/reactiveVars';
import { Workspace } from 'generated/graphql';
import * as path from 'constants/routes';
import { AppContext } from 'contexts/app/AppContext';
import { ActionsModal, EditFieldModal } from 'components/organisms';
import { Tooltip, Spinner, Icon } from 'components/atoms';
import { LogoIcon, AddIcon } from 'components/atoms/icon';
import womanAvatar from 'assets/icons/woman.svg';
import SwitchIcon from 'assets/icons/switch.svg';
import chooseFreeNumberImg from 'assets/icons/mobile-chat-img.svg';
import { useChatBot } from 'hooks/useChatBot';
import { useAllWorkspaceNumbers } from 'hooks/useAllWorkspaceNumbers';
import { ENABLE_ADD_WORKSPACE_FEATURE } from 'constants/featureFlags';

import * as S from './Styles';
import UserSettings from './UserSettings';
import WorkspaceProfile from './WorkspaceProfile';
import useWorkspaceList from './useWorkspaceList';

export default function Index() {
  const { t } = useTranslation();
  const history = useHistory();
  const { numbers, loading: loadingNumbers } = useAllWorkspaceNumbers();
  const { chooseFreeNumberVisible, setChooseFreeNumberVisible } = useContext(AppContext);
  const loadingWorkspace = useReactiveVar(loadingSwitchWorkspace);

  const {
    activeWorkspaceId,
    openStayAwayModel,
    isDeletedWorkspace,
    workspaceSwitchModal,
    toggleSwitchWorkspaceModal,
    handleWorkspaceSwitch,
    onSwitchAllowded,
    workspaceList,
    onStayAway,
    goOnline,
    defaultWorkspace,
    handleDontShowPrompt,
    handleDragEnd,
    addNewWks,
  } = useWorkspaceList();
  const { verifyChat } = useChatBot();

  const hasNoNumber = numbers?.length <= 0;
  const onLater: any = () => {
    setChooseFreeNumberVisible?.(false);
  };
  const gotoBuyNumber = () => {
    history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`);
    setChooseFreeNumberVisible?.(false);
  };
  const goToDashboard = () => {
    history.push(`${path.DASHBOARD}`);
  };

  const kycUnverifiedtoolTipMsg = () => {
    return (
      <div className='py-1.5 max-w-182'>
        <p className='text-gray-100 text-13  font-heebo leading-4.5 mb-2'>
          Your Account is currently limited. Please verify your account by updating your KYC.
        </p>
        <button
          type='button'
          onClick={verifyChat}
          className='rounded text-13 font-semibold font-manrope  leading-tight w-full h-7 flex items-center justify-center text-gray-100 border border-gray-600'
        >
          Verify Now
        </button>
      </div>
    );
  };

  const draggableWorkspaceList = workspaceList?.map((workspace: Workspace, index: number) => {
    const { kycVerified, id } = workspace || {};
    const currentActive = activeWorkspaceId === id;

    return (
      <Draggable
        key={id}
        draggableId={id}
        index={index}
        isDragDisabled={workspace.status === 'Inactive'}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className='mb-4 relative'
          >
            {!kycVerified && (
              <Tooltip title={kycUnverifiedtoolTipMsg()} placement='bottomRight'>
                <Icon
                  name='exclamation-circle-fill'
                  className='absolute z-1 -top-0.75 right-1.5 cursor-default'
                />
              </Tooltip>
            )}
            <WorkspaceProfile
              id={id}
              onClick={handleWorkspaceSwitch(workspace)}
              isActive={currentActive}
              workspace={workspace}
            />
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <>
      <div className='w-full h-full overflow-hidden'>
        <S.LogoWrapperBtn onClick={goToDashboard}>
          <LogoIcon className='mx-auto mb-4' />
        </S.LogoWrapperBtn>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable'>
            {provided => (
              <S.StyledScrollbar className='w-full'>
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Suspense fallback={<Spinner />}>{draggableWorkspaceList}</Suspense>
                  {provided.placeholder}
                </div>
              </S.StyledScrollbar>
            )}
          </Droppable>
        </DragDropContext>
        {ENABLE_ADD_WORKSPACE_FEATURE && (
          <Tooltip title={t('addWorkspace', 'Add Workspace')} placement='right'>
            <div className='h-8 w-8 mx-auto'>
              <button
                data-cy='add-new-workspace'
                className='h-8 w-8 p-2.5 rounded-9 hover:bg-primary-500 transition duration-200 ease-out'
                type='button'
                onClick={addNewWks}
              >
                <AddIcon />
              </button>
            </div>
          </Tooltip>
        )}
      </div>

      <UserSettings />

      {openStayAwayModel && !isDeletedWorkspace && (
        <EditFieldModal
          isModalOpen={openStayAwayModel && !isDeletedWorkspace}
          className='set-yourself-online'
          onOk={goOnline}
          onCancel={onStayAway}
          subTitle={t(
            'goOnlinePromptMessage',
            'You’re currently set to away. Do you want to update your availability?',
          )}
          title={
            <S.ModalHead>
              <img src={womanAvatar} alt='woman avatar krispcall' className='cy-wks-avatar' />
              <div>{t('setYourselfOnline', 'Set yourself online?')}</div>
            </S.ModalHead>
          }
          buttonText={t('goOffline', 'Go Online')}
          buttonTextCancel={t('stayOffline', 'Stay Offline')}
        >
          <S.CheckboxStyled onChange={handleDontShowPrompt} name='Don’t ask again.'>
            {t('dontAskAgain', 'Don’t ask again.')}
          </S.CheckboxStyled>
        </EditFieldModal>
      )}

      {!loadingWorkspace &&
        !loadingNumbers &&
        !openStayAwayModel &&
        chooseFreeNumberVisible &&
        hasNoNumber && (
          <EditFieldModal
            isModalOpen={chooseFreeNumberVisible}
            className='choose-one-free-number'
            width='388px'
            onCancel={onLater}
            onOk={gotoBuyNumber}
            subTitle='Welcome to your workspace! Get started by selecting one free number from the US, Canada, or UK. You can always purchase more numbers later from Settings > My Numbers'
            title={
              <S.ModalHead>
                <img src={chooseFreeNumberImg} alt='Choose Free Number' />
                <div>Choose One Free Number</div>
              </S.ModalHead>
            }
            buttonTextCancel='Later'
            buttonText='Choose Number'
          />
        )}

      {workspaceSwitchModal && (
        <ActionsModal
          type='primary'
          isModalOpen={workspaceSwitchModal}
          onOk={onSwitchAllowded}
          onCancel={toggleSwitchWorkspaceModal}
          onModalCancel={toggleSwitchWorkspaceModal}
          image={SwitchIcon}
          title={t('workspaceSwitchTitle', 'Switch Workspace?')}
          info={t(
            'workspaceSwitchInfo',
            'Are you sure you want to switch workspace? The call in progress will end if you switch.',
          )}
          btnText='Switch'
        />
      )}
    </>
  );
}
