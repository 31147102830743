import { useHistory } from 'react-router-dom';

import * as path from 'constants/routes';
import useScreenType from 'hooks/useScreenType';

import useAuthContext from 'hooks/useAuthContext';
import * as S from '../Styles';

const SubsExpiredNotification = () => {
  const history = useHistory();
  const { isTablet, isMobile } = useScreenType();
  const { userRole } = useAuthContext();

  const goToPlan = () => history.push(`${path.SETTINGS}${path.PLAN_SUBS}`);

  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={
          <>
            <p className='hidden lg:inline-block'>
              Your subscription has been expired.
              {userRole !== 'Member' ? (
                <button type='button' onClick={goToPlan} className='mr-2'>
                  Renew
                </button>
              ) : (
                <> Renew </>
              )}
              subscription to resume services.
            </p>
            <p className='lg:hidden'>
              Subscription expired.
              {userRole !== 'Member' ? (
                <button type='button' onClick={goToPlan} className='mr-2'>
                  Renew
                </button>
              ) : (
                <> Renew </>
              )}
              subscription to resume services.
            </p>
          </>
        }
        type='error'
        banner
        className={`has-bordered-btn ${isTablet || isMobile ? 'alert-sm' : ''}`}
      />
    </S.AlertWrapper>
  );
};

export default SubsExpiredNotification;
