import { Device } from '@twilio/voice-sdk';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import fernet from 'fernet';
import { datadogLogs } from '@datadog/browser-logs';

import { ToastMessage } from 'components/atoms';
import { VOICE_TOKEN } from 'graphql/channel/phoneWidget';
import { SECRET_KEY } from 'constants/endpoint';
import useLocalStorage from 'hooks/useLocalStorage';
import { isNumberPurchasedWks } from 'services/apollo/reactiveVars';

interface IProps {
  deviceInstance: Device | null;
  setDeviceInstance: (device: Device | null) => void;
  setDeviceStatus: (val: string) => void;
}
export function useTwilioQuery({ deviceInstance, setDeviceInstance, setDeviceStatus }: IProps) {
  const { t } = useTranslation();
  const voiceTokenRefreshMs = process.env.REACT_APP_VOICE_TOKEN_REFRESH_MS || '120000';
  const [, setVoiceToken] = useLocalStorage('_voice-tk', null);

  const destroyDevice = () => {
    deviceInstance?.destroy();
    setDeviceInstance(null);
    setDeviceStatus('offline');
    setVoiceToken(null);
  };
  const destroyDeviceToBuyNumber = () => {
    deviceInstance?.destroy();
    setDeviceInstance(null);
    setVoiceToken(null);
  };

  const [getVoiceToken, { data, loading: loadingVoiceToken }] = useLazyQuery(VOICE_TOKEN, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    onError: () => {
      destroyDevice();
    },
    onCompleted: res => {
      const { error, data: responseData } = res?.getVoiceToken || {};
      if (error === null && SECRET_KEY) {
        isNumberPurchasedWks(true);
        try {
          const secret = new fernet.Secret(SECRET_KEY);
          const token = new fernet.Token({
            secret,
            token: responseData.voiceToken,
            ttl: 0,
          });
          const decodedToken = token.decode();
          setVoiceToken(decodedToken);
          if (deviceInstance) {
            deviceInstance.updateToken(decodedToken);
            return;
          }
          const device = new Device(decodedToken, {
            enableImprovedSignalingErrorPrecision: true,
            tokenRefreshMs: parseInt(voiceTokenRefreshMs, 10),
          });
          setDeviceInstance(device);

          return;
        } catch (e) {
          console.error(e);
          datadogLogs.logger.error('Twilio Error : Device is offline [Get voice token]', {
            datadogError: e,
            context: 'twilio device',
            additionalInfo: {
              responseData,
            },
          });
          ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
        }
        return;
      }
      if (error?.message.includes('buy phone number')) {
        isNumberPurchasedWks(false);
        destroyDeviceToBuyNumber();
        return;
      }
      // Hide Device offline alert banner if selected workspace has no active twilio numbers
      if (error?.errorKey === 'does_not_exists') return;
      datadogLogs.logger.error('Twilio Error : Device is offline [Get voice token]', {
        datadogError: error,
        context: 'twilio device',
        additionalInfo: {
          responseData,
        },
      });
      destroyDevice();
    },
  });

  return {
    data,
    getVoiceToken,
    loadingVoiceToken,
  };
}
