import { lazy } from 'react';

import * as path from 'constants/routes';
import { roles } from 'constants/roles';
import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';

// Auth
const Login = lazy(() => import('components/pages/authentication/login'));
const Registration = lazy(() => import('components/pages/authentication/registration'));
const ConfirmEmail = lazy(() => import('components/pages/authentication/verify-email'));
const InviteeLogin = lazy(() => import('components/pages/authentication/invitee-login'));
const ResetPassword = lazy(() => import('components/pages/authentication/reset-password'));
const ForgotPassword = lazy(() => import('components/pages/authentication/forgot-password'));
const AccountSetupGuide = lazy(() => import('components/pages/authentication/account-setup-guide'));
const LoginOtpVerification = lazy(
  () => import('components/pages/authentication/login/otp-verification'),
);

// App
const Inbox = lazy(() => import('components/pages/inbox'));
const NonVoipInbox = lazy(() => import('components/pages/inbox-non-voip'));
const Settings = lazy(() => import('components/pages/settings'));
const Contacts = lazy(() => import('components/pages/contacts'));
const ChatRoom = lazy(() => import('components/pages/chat-room'));
const Workspace = lazy(() => import('components/pages/workspace'));
const TeamMembers = lazy(() => import('components/pages/team-members'));
const DashBoard = lazy(() => import('components/pages/dashboard/Dashboard'));
const DownloadApps = lazy(() => import('components/pages/download-apps'));
const SalesDialer = lazy(() => import('components/pages/sales-dialer'));

// Dialer
const Dialer = lazy(() => import('components/pages/dialer'));

// Support
const AuthSetup = lazy(
  () => import('components/pages/support/authorization-setup/AuthorizationSetup'),
);
const AuthSetupSupport = lazy(
  () => import('components/pages/support/authorization-setup/SupportUserAuthorization'),
);
const PLAN_SUPPORT = lazy(() => import('components/pages/support/plan'));
const PORT_SUPPORT = lazy(() => import('components/pages/support/porting'));
const PURCHASE_SUPPORT = lazy(() => import('components/pages/support/purchase'));
const NON_VOIP_PURCHASE_SUPPORT = lazy(() => import('components/pages/support/non-voip-purchase'));
const PlansSetup = lazy(() => import('components/pages/support/plans-setup/PlansSetup'));
const FEEDBACKS = lazy(() => import('components/pages/support/feedbacks'));
const WORKSPACES = lazy(() => import('components/pages/support/workspaces'));
const CANCELLED_WORKSPACES = lazy(() => import('components/pages/support/cancel-workspaces'));
const DELETED_WORKSPACES = lazy(() => import('components/pages/support/deleted-workspaces'));
const WORKSPACE_INVOICES = lazy(() => import('components/pages/support/workspaces/view-invoices'));
const DELETED_WORKSPACE_INVOICES = lazy(
  () => import('components/pages/support/deleted-workspaces/view-invoices'),
);

const WORKSPACE_CREDIT_LOGS = lazy(() => import('components/pages/support/workspaces/credit-logs'));
const USERS = lazy(() => import('components/pages/support/users'));
const USER_ACTIVITIES = lazy(() => import('components/pages/support/users/activities'));
const ACCOUNT_REVIEW = lazy(() => import('components/pages/support/account-review'));
const EMAIL_VERIFICATION = lazy(() => import('components/pages/support/email-verification'));
const SETUP_APPS = lazy(() => import('components/pages/support/apps'));
const INCOMPLETE_SIGNUP = lazy(() => import('components/pages/support/incomplete-signup'));
const COMPLETE_SIGNUP = lazy(() => import('components/pages/support/complete-signup'));
const WORKSPCAE_CREDIT = lazy(() => import('components/pages/support/workspace-credit'));
const WORKSPACE_CREDIT_LOGS_DEV = lazy(
  () => import('components/pages/support/workspace-credit/credit-logs'),
);
const WORKSPACE_SPAM = lazy(() => import('components/pages/support/workspace-spam'));
const AUDIT_LOG = lazy(() => import('components/pages/support/audit-log'));
const NUMBER_RATES = lazy(() => import('components/pages/support/number-rates'));
const WORKSPACES_INACTIVE = lazy(() => import('components/pages/support/workspaces-inactive'));

const Plans = lazy(() => import('components/pages/settings/plan-subscription/plans'));
const MaintainencePage = lazy(() => import('components/pages/errors/MaintainencePage'));
const BookDemo = lazy(() => import('components/pages/errors/BookDemo'));

const BULK_SMS = lazy(() => import('components/pages/bulk-sms'));

const Helpscout = lazy(() => import('components/pages/settings/integration/Helpscout'));

const defaultRoutes = [
  {
    path: path.DASHBOARD,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: DashBoard,
    exact: true,
  },
  {
    path: path.DIALER,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Dialer,
    exact: true,
  },
  {
    path: path.LOGIN,
    component: Login,
    exact: true,
  },
  {
    path: path.LOGIN_OTP_VERIFICATION,
    component: LoginOtpVerification,
    exact: true,
  },
  {
    path: path.REGISTER,
    component: Registration,
    exact: true,
  },
  {
    path: path.ACCOUNT_SETUP_GUIDE,
    component: AccountSetupGuide,
    exact: true,
  },
  {
    path: path.NEW_WORKSPACE,
    component: AccountSetupGuide,
    exact: true,
  },
  {
    path: path.BOOK_DEMO,
    component: BookDemo,
    exact: true,
  },
  {
    path: path.REGISTER_TEMPORARY,
    component: Registration,
    exact: true,
  },
  {
    path: path.FORGOT_PASSWORD,
    component: ForgotPassword,
    exact: true,
  },
  {
    path: `${path.INVITE}/member`,
    component: InviteeLogin,
  },
  {
    path: path.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: '/registration-verify-email',
    component: ConfirmEmail,
  },
  {
    path: `${path.CONTACTS}/:contactId?`,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Contacts,
    exact: true,
  },
  {
    path: `${path.CONTACTS}/:tags/tags`,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Contacts,
    exact: true,
  },
  {
    path: path.DOWNLOAD_APPS,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: DownloadApps,
  },
  {
    path: path.SALES_DIALER,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: SalesDialer,
  },
  {
    path: path.SETTINGS,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Settings,
  },
  {
    path: `${path.CHOOSE_PLAN}`,
    component: Plans,
    exact: true,
  },
  {
    path: `${path.NUMBERS}/:channelId/:contactId?`,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Inbox,
  },
  {
    path: `${path.MEMBERS}/:memberId?`,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: ChatRoom,
  },
  {
    path: `${path.TEAM}/:teamId/:memberId?`,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: TeamMembers,
  },
  {
    path: path.WORKSPACE_RESTORE,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Workspace,
  },
  {
    path: path.WORKSPACE_VIEW,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: DashBoard,
  },
  {
    path: path.PURCHASE,
    roleList: [roles.SUPPORT],
    component: PURCHASE_SUPPORT,
    exact: true,
  },
  {
    path: path.PORTING,
    roleList: [roles.SUPPORT],
    component: PORT_SUPPORT,
    exact: true,
  },
  {
    path: path.PLAN,
    roleList: [roles.SUPPORT],
    component: PLAN_SUPPORT,
    exact: true,
  },
  {
    path: path.FEEDBACKS,
    roleList: [roles.SUPPORT],
    component: FEEDBACKS,
    exact: true,
  },
  {
    path: path.WORKSPACES,
    roleList: [roles.SUPPORT],
    component: WORKSPACES,
    exact: true,
  },
  {
    path: path.CANCELLED_WORKSPACES,
    roleList: [roles.SUPPORT],
    component: CANCELLED_WORKSPACES,
    exact: true,
  },
  {
    path: path.DELETED_WORKSPACES,
    roleList: [roles.SUPPORT],
    component: DELETED_WORKSPACES,
    exact: true,
  },
  {
    path: path.USERS,
    roleList: [roles.SUPPORT],
    component: USERS,
    exact: true,
  },
  {
    path: `${path.USERS}/:userID/activities`,
    roleList: [roles.SUPPORT],
    component: USER_ACTIVITIES,
  },
  {
    path: path.AUTHORIZATION,
    roleList: [roles.DEVELOPER],
    component: AuthSetup,
    exact: true,
  },
  {
    path: path.SUPPORT_AUTHORIZATION,
    roleList: [roles.DEVELOPER],
    component: AuthSetupSupport,
    exact: true,
  },
  {
    path: path.PLANSETUP,
    roleList: [roles.DEVELOPER],
    component: PlansSetup,
    exact: true,
  },
  {
    path: path.SETUP_APPS,
    roleList: [roles.DEVELOPER],
    component: SETUP_APPS,
    exact: true,
  },
  {
    path: path.NUMBER_RATES,
    roleList: [roles.DEVELOPER],
    component: NUMBER_RATES,
    exact: true,
  },
  {
    path: path.ACCOUNT_REVIEW,
    roleList: [roles.SUPPORT],
    component: ACCOUNT_REVIEW,
    exact: true,
  },
  //  TODO: BULK SMS disabled for release 2.14.4 for production server. Need to enable bulk sms in next release
  {
    path: path.BULK_SMS,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: BULK_SMS,
  },
  {
    path: path.EMAIL_VERIFICATION,
    roleList: [roles.SUPPORT],
    component: EMAIL_VERIFICATION,
    exact: true,
  },
  {
    path: path.INCOMPLETE_SIGNUP,
    roleList: [roles.SUPPORT],
    component: INCOMPLETE_SIGNUP,
    exact: true,
  },
  {
    path: path.COMPLETE_SIGNUP,
    roleList: [roles.SUPPORT],
    component: COMPLETE_SIGNUP,
    exact: true,
  },
  {
    path: path.WORKSPACE_CREDIT,
    roleList: [roles.DEVELOPER],
    component: WORKSPCAE_CREDIT,
    exact: true,
  },
  {
    path: `${path.WORKSPACES}/:workspaceID/credit-logs`,
    roleList: [roles.SUPPORT],
    component: WORKSPACE_CREDIT_LOGS,
  },
  {
    path: `${path.WORKSPACES}/:workspaceID${path.INVOICES}`,
    roleList: [roles.SUPPORT],
    component: WORKSPACE_INVOICES,
  },
  {
    path: `${path.DELETED_WORKSPACES}/:workspaceID${path.INVOICES}`,
    roleList: [roles.SUPPORT],
    component: DELETED_WORKSPACE_INVOICES,
  },
  {
    path: `${path.WORKSPACE_CREDIT}/:workspaceID/credit-logs`,
    roleList: [roles.DEVELOPER],
    component: WORKSPACE_CREDIT_LOGS_DEV,
    exact: true,
  },
  {
    path: path.WORKSPACE_SPAM,
    roleList: [roles.SUPPORT],
    component: WORKSPACE_SPAM,
    exact: true,
  },
  {
    path: path.WORKSPACES_INACTIVE,
    roleList: [roles.SUPPORT],
    component: WORKSPACES_INACTIVE,
    exact: true,
  },
  {
    path: path.AUDIT_LOG,
    roleList: [roles.SUPPORT],
    component: AUDIT_LOG,
    exact: true,
  },

  // helpscout success page
  {
    path: path.HELPSCOUT_SUCCESS,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: Helpscout,
    exact: true,
  },
];

const nonVoipNumberRoutes = [
  {
    path: `${path.NON_VOIP_NUMBERS}/:channelId/:contactId?`,
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    component: NonVoipInbox,
  },
  {
    path: path.NON_VOIP_PURCHASE,
    roleList: [roles.SUPPORT],
    component: NON_VOIP_PURCHASE_SUPPORT,
    exact: true,
  },
];

export const routes = ENABLE_NON_VOIP_FEATURE
  ? [...defaultRoutes, ...nonVoipNumberRoutes]
  : defaultRoutes;
