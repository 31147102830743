import { RiCloseLine, RiArrowLeftLine } from 'react-icons/ri';

import { Button, Icon } from 'components/atoms';
import { Checkbox } from 'components/molecules/fields';

import OfferIcon from 'components/atoms/icon/OfferIcon';
import * as S from './Styles';

interface RequestFormProps {
  prev?: () => void;
  handleSupport: () => void;
  handleToggleCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  requestNonVoip: () => void;
  processing: boolean;
  confirmation: boolean;
}

export const RequestForm = ({
  prev,
  handleSupport,
  handleToggleCheckbox,
  requestNonVoip,
  processing,
  confirmation,
}: RequestFormProps) => {
  const nonVoipPurchaseAmount: any = process.env.REACT_APP_NON_VOIP_OFFER_PRICE;
  const nonVoipDiscountPercentage: any = process.env.REACT_APP_NON_VOIP_DISCOUNT_PERCENT;
  const discountAmount = (nonVoipDiscountPercentage / 100) * nonVoipPurchaseAmount;
  const priceAfterDiscount = nonVoipPurchaseAmount - discountAmount;

  const hasNonVoipOffer = !!(
    process.env.REACT_APP_NON_VOIP_OFFER_PRICE && process.env.REACT_APP_NON_VOIP_DISCOUNT_PERCENT
  );

  const amount: number = parseFloat(process.env.REACT_APP_NON_VOIP_PRICE as string) || 75;

  const checkboxContent = `I acknowledge that by requesting I will be invoiced an amount of $${
    hasNonVoipOffer ? priceAfterDiscount.toFixed(2) : amount.toFixed(2)
  } USD`;
  const methodListOption = [
    {
      step: 1,
      title: 'Pay & Request number',
      desc:
        'After successful payment our verification process will begin which usually takes 1 to 2 business days.',
    },
    {
      step: 2,
      title: 'Support will chat with you',
      desc: 'If any additional documents are needed our support team will contact you.',
    },
    {
      step: 3,
      title: 'Use your number',
      desc: 'Start using your new number as soon as it’s verified and assigned.',
    },
  ];
  return (
    <>
      <div className='flex justify-between w-full'>
        {prev && (
          <Button onClick={prev} className='border-0 btn-prev'>
            <RiArrowLeftLine color='#6E6681' />
          </Button>
        )}
      </div>
      <div className='request-form mb-5 pt-0'>
        <Icon name='request-nonvoip' className='icon-center' />
        {hasNonVoipOffer && (
          <S.LimitOfferWrapper className='flex'>
            <OfferIcon /> Limited time {nonVoipDiscountPercentage}% off
          </S.LimitOfferWrapper>
        )}

        <h5 data-cy='request-non-voip-title'>Request Non-VOIP Number</h5>
        <p data-cy='request-non-voip-desc text-center'>
          A real phone number based on physical SIM card. You will be charged
          {hasNonVoipOffer && (
            <S.StrikeThroughText className='ml-1'>${nonVoipPurchaseAmount}</S.StrikeThroughText>
          )}
          <span className={`ml-1 font-bold ${hasNonVoipOffer ? 'text-success' : ''}`}>
            ${priceAfterDiscount ? priceAfterDiscount.toFixed(2) : amount.toFixed(2)} USD
          </span>
          /month.
        </p>
        <div className='mt-7'>
          <Checkbox
            data-cy='request-modal-checkbox'
            name='request-confirmation'
            onChange={handleToggleCheckbox}
          >
            {checkboxContent}
          </Checkbox>
        </div>
        <Button
          className='capitalize btn w-full mt-3'
          type='primary'
          size='large'
          onClick={requestNonVoip}
          loading={processing}
          disabled={!confirmation ?? 'disabled'}
        >
          Request Number
        </Button>
      </div>
      <div className='border-top w-full pb-6 text-center'>
        <p>
          Have any queries?
          <button
            type='button'
            className='ml-2 text-14 text-info font-medium'
            onClick={handleSupport}
          >
            Chat with support
          </button>
        </p>
      </div>
      <S.ListWrapper className='request-method pt-10'>
        <div>
          <span className='py-4 text-14 font-medium text-gray-500'>How it works</span>
          <div className='mt-5'>
            {methodListOption.map((list: any) => (
              <li>
                <span className='count font-medium text-20'>{list.step}</span>
                <div className='text-left ml-2'>
                  <span className='font-medium text-gray-600 text-15'>{list.title}</span>
                  <p>{list.desc}</p>
                </div>
              </li>
            ))}
          </div>
        </div>
      </S.ListWrapper>
    </>
  );
};
