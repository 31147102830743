/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from 'antd';
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { PaymentGatewayTypesEnum } from 'constants/paymentGateway';
import { logInfoDatdog } from 'services/datadog/helper';
import { useChargebeeMutation } from 'lib/chargebee/hooks/useChargebeeMutation';
import { useDefaultCardQuery } from 'lib/chargebee/hooks/useDefaultCardQuery';
import { mapPaymentIntentData } from 'lib/chargebee/utils';
import { Input, SelectInput, Checkbox } from 'components/molecules/fields';
import { INVITE_MEMBER_EMAIL_EXISTS } from 'graphql/foundation';
import { Button, ToastMessage } from 'components/atoms';
import { useMembersQuery } from 'components/organisms/navigation/workspace-detail-sidebar/members/useMembersQuery';
import useAuthContext from 'hooks/useAuthContext';
import { schema } from './schema';
import { useInvite } from './useInvite';
import * as S from './Styles';

type Role = 'Member' | 'Admin';

interface InviteMemberInputs {
  email: string;
  role: Role;
}

interface IForm {
  onCancel: () => void;
  openInvitationSentModal: (email: string) => void;
  processing: boolean;
  setProcessing: (val: boolean) => void;
}

const Form = ({ onCancel, openInvitationSentModal, processing, setProcessing }: IForm) => {
  const cbInstance = window?.Chargebee?.getInstance?.();
  const cardIdRef = useRef<any>();
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const [role, setRole] = useState<Role>('Admin');
  const { activeWorkspaceId } = useAuthContext();

  const { email, requesting, inviteMember, onEmailChange } = useInvite({
    callback: onCancel,
    openInvitationSentModal,
  });
  const { defaultPaymentCard } = useDefaultCardQuery();
  cardIdRef.current = defaultPaymentCard;
  const { createPaymentIntent } = useChargebeeMutation();
  const { members } = useMembersQuery();
  const { node } = members?.[0] || {};
  const amount = node?.planRate?.planRate;
  const checkboxContent = `I acknowledge that by inviting this person I will be invoiced a prorated amount
     of $${amount} USD and that a recurring amount of $${amount} USD will be added to my subscription
      starting with my next invoice.`;

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<InviteMemberInputs>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { email, role: 'Admin' },
  });

  React.useEffect(() => {
    if (current === 0 && email) {
      setValue('email', email);
    }
  }, [current, email, setValue]);

  // FETCH PAYMENT INTENT
  const fetchPaymentIntent = async () => {
    const { id: defaultPaymentCardId } = cardIdRef.current || {};
    try {
      setProcessing(true);
      const intents = await createPaymentIntent({
        variables: {
          data: {
            amount: 0,
            paymentMethodId: defaultPaymentCardId, // card id is sent in paymentMethodId
            paymentIntentType: 'NewAgent',
            workspaceId: activeWorkspaceId,
          },
        },
      });
      const paymentIntentResponse = intents?.data?.createPaymentIntent;
      const paymentIntent = paymentIntentResponse?.data || {};
      if (paymentIntentResponse?.error) {
        setProcessing(false);
        return;
      }
      // eslint-disable-next-line consistent-return
      return mapPaymentIntentData(paymentIntent);
    } catch (error: any) {
      setProcessing(false);
      const additionalInfo = {
        paymentIntentPayload: {
          amount: 0,
          paymentMethodId: defaultPaymentCardId, // card id is sent in paymentMethodId
          paymentIntentType: 'NewAgent',
          workspaceId: activeWorkspaceId,
        },
        context: 'Send Member Invitation',
        errText: 'Send Member Invitation Failed',
      };
      logInfoDatdog(error, additionalInfo);
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    }
  };

  const sendInvitation = async () => {
    const paymentIntentData = await fetchPaymentIntent();
    if (!paymentIntentData) return;
    const { payment_method_type: paymentMethodType } = paymentIntentData;
    const args = {
      email: email.toLowerCase(),
      role,
      inviteLink: 'invite/member',
      amount: paymentIntentData.amount,
      gatewayAccountId: paymentIntentData.gateway_account_id,
    };
    if (paymentMethodType === PaymentGatewayTypesEnum.Paypal) {
      await inviteMember(args);
      setProcessing(false);
      return;
    }
    cbInstance.load3DSHandler().then((threeDSHandler: any) => {
      threeDSHandler.setPaymentIntent(paymentIntentData);
      threeDSHandler
        .handleCardPayment()
        .then(async (intent: any) => {
          await inviteMember({
            ...args,
            gwToken: intent?.active_payment_attempt?.id_at_gateway,
          });
          setProcessing(false);
        })
        .catch((error: any) => {
          setProcessing(false);
          const { displayMessage, message } = error || {};
          const errMsg =
            displayMessage || message || t('error.unspecific', 'Something went wrong.');
          const additionalInfo = {
            paymentIntentData,
            context: 'Send Member Invitation',
            errText: 'Send Member Invitation Failed',
          };
          logInfoDatdog(error, additionalInfo);
          ToastMessage({
            content: errMsg,
            type: 'danger',
          });
        });
    });
  };

  const [inviteEmailExists, { loading }] = useLazyQuery(INVITE_MEMBER_EMAIL_EXISTS, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      const { success } = response.inviteMemberEmailExists?.data || {};
      if (!success) {
        sendInvitation();
        return;
      }
      setError('email', {
        type: 'manual',
        message: t('toast.emailAlreadyExist', 'Email already exist'),
      });
    },
  });

  const handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const payload = evt.target.value;
    onEmailChange(payload, setValue);
  };

  const handleRoleChange = (roleValue: any) => {
    setRole(roleValue as any);
  };

  const formSubmit = () => {
    inviteEmailExists({
      variables: {
        email,
      },
    });
  };

  const handleToggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(e.target.checked);
  };

  return (
    <S.AddMemberForm
      data-cy='tab-pane-add-button-modal-form-wrapper'
      onSubmit={handleSubmit(formSubmit)}
      className='mt-6 w-full'
    >
      <div className='relative'>
        <Input
          data-cy='tab-pane-add-button-modal-form-email-field'
          name='email'
          id='email'
          control={control}
          onChange={handleEmailChange}
          placeholder={t('enterEmailAddress', 'Enter email address...')}
          errors={errors}
          label={t('form.label.emailAddress', 'Email Address')}
        />
        <div className='select-field-wrapper'>
          <SelectInput
            name='role'
            id='role'
            defaultValue={role}
            control={control}
            onChange={handleRoleChange}
            data-cy='tab-pane-add-button-modal-form-select-field'
          >
            <Select.Option value='Admin'>{t('admin', 'Admin')}</Select.Option>
            <Select.Option value='Member'>{t('member', 'Member')}</Select.Option>
          </SelectInput>
        </div>
      </div>
      {amount >= 0 && (
        <div className='mt-4'>
          <Checkbox
            data-cy='tab-pane-add-button-modal-form-radio-field'
            name='invite-confirmation'
            onChange={handleToggleCheckbox}
          >
            {checkboxContent}
          </Checkbox>
        </div>
      )}
      <Button
        key='send'
        htmlType='submit'
        type='primary'
        size='large'
        className='w-full mt-6'
        loading={loading || requesting || processing}
        disabled={!confirmation}
        data-cy='tab-pane-add-button-modal-form-button-field'
      >
        Send
      </Button>
    </S.AddMemberForm>
  );
};

export default Form;
