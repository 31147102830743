import { useEffect, useMemo } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import SecureLS from 'secure-ls';

import useDraftMessage from 'hooks/useDraftMessage';
import { FETCH_AUTH_DATA } from 'graphql/authorization/query';
import { FETCH_PLAN_DATA } from 'graphql/krispcall-plans/query';
import { CHAT_SUBSCRIPTION } from 'graphql/chat-room';
import { GET_DRAFT_MSG } from 'graphql/channel/conversation';
import { useActiveWorkspaceQuery } from 'hooks/useActiveWorkspaceQuery';
import useAuthContext from 'hooks/useAuthContext';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export function useNavHelper() {
  const { createMessageId } = useDraftMessage();
  const { data: chatSubscriptionData } = useSubscription(CHAT_SUBSCRIPTION);
  const { loadWorkspace, loadingActiveWorkspace, activeWorkspaceData } = useActiveWorkspaceQuery();

  const [fetchAuthData] = useLazyQuery(FETCH_AUTH_DATA, {
    fetchPolicy: 'network-only',
  });
  const [fetchPlanData] = useLazyQuery(FETCH_PLAN_DATA, {
    fetchPolicy: 'network-only',
  });
  const { activeWorkspaceId } = useAuthContext();

  const [loadDraftMessages] = useLazyQuery(GET_DRAFT_MSG, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      const { error, data: draftMsgList } = response.workspaceDrafts;
      if (error === null) {
        const draftMessageList: {
          messageId: string;
          draftMessage: string;
          id: string;
          isSavedOnServer: boolean;
        }[] = [];
        draftMsgList.forEach((eachMessage: { messages: never[]; channel: any }) => {
          const messageList = eachMessage?.messages ?? [];
          const channelId = eachMessage?.channel;
          messageList.forEach((msg: { clientNumber: string; content: any; id: any }) => {
            draftMessageList.push({
              messageId: createMessageId(1, msg.clientNumber, channelId),
              draftMessage: msg.content,
              id: msg.id,
              isSavedOnServer: true,
            });
          });
        });
        ls.set('_dmsgs', JSON.stringify(draftMessageList));
      }
    },
  });

  useEffect(() => {
    if (activeWorkspaceId) {
      loadWorkspace({});
      fetchPlanData();
      fetchAuthData({});
      loadDraftMessages({
        variables: {
          workspace: activeWorkspaceId,
        },
      });
    }
  }, [loadWorkspace, fetchAuthData, activeWorkspaceId, fetchPlanData, loadDraftMessages]);

  return useMemo(
    () => ({
      loadingActiveWorkspace,
      activeWorkspaceData,
      chatSubscriptionData,
    }),
    [loadingActiveWorkspace, activeWorkspaceData, chatSubscriptionData],
  );
}
