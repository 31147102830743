import { useEffect, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { MY_NUMBERS, MY_VOIP_NONVOIP_NUMBERS } from 'graphql/channel/settings';
import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';
import { NON_VOIP_NUMBER_REQUEST_COUNT } from 'graphql/channel';

const filterListObject = (query: string, list: any, keys: string[]) => {
  const lowSearch = query.toLowerCase();
  return list?.filter?.((item: any) =>
    keys?.some(key => String(item[key]).toLowerCase().includes(lowSearch)),
  );
};

export function useNonVoipNumbersQuery() {
  const [numberList, setNumberList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [loadMyNumbers, { data, loading: fetchingNumbers, called }] = useLazyQuery(
    ENABLE_NON_VOIP_FEATURE ? MY_VOIP_NONVOIP_NUMBERS : MY_NUMBERS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (data?.numbers.error === null) {
      setNumberList(data?.numbers?.data ?? []);
    }
  }, [data]);

  useEffect(() => {
    if (!called) {
      loadMyNumbers({
        variables: ENABLE_NON_VOIP_FEATURE
          ? {
              numberTypes: ['NonVoip'],
            }
          : {},
      });
    }
  }, [called, loadMyNumbers]);

  const onSearch = useCallback(
    (evt: any) => {
      const keyword = evt.target.value;
      setSearchTerm(keyword);
      const filteredNumbers = filterListObject(keyword, data?.numbers?.data, ['name', 'number']);
      setNumberList(filteredNumbers);
    },
    [data],
  );

  const [
    requestNonVoipNumberCount,
    { data: requestNonVoipNumberCountResponse, loading: loadingRequestCount },
  ] = useLazyQuery(NON_VOIP_NUMBER_REQUEST_COUNT, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    requestNonVoipNumberCount();
  }, [requestNonVoipNumberCount]);

  const hasNumbers = numberList?.length > 0;
  const nonVoipNumberRequestCount =
    requestNonVoipNumberCountResponse?.nonVoipNumberRequestCount?.data?.pendingTotal;
  return {
    hasNumbers,
    numberList,
    onSearch,
    fetchingNumbers,
    data,
    loadingRequestCount,
    nonVoipNumberRequestCount,
  };
}
