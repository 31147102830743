import { useEffect, useState } from 'react';
import useAuthContext from 'hooks/useAuthContext';
import { restrictedScreenData } from './constants';

export const useRestrictedEventInfo = () => {
  const [restrictedEventInfo, setRestrictedEventInfo] = useState<any>(null);

  const {
    isDeletedWorkspace,
    isSubscriptionCancelled,
    isSubscriptionExpired,
    isUserSuspended,
    isUnderReview,
  } = useAuthContext();

  useEffect(() => {
    if (isUserSuspended) setRestrictedEventInfo(restrictedScreenData.isUserSuspended);
    if (isDeletedWorkspace) setRestrictedEventInfo(restrictedScreenData.isDeletedWorkspace);
    if (isUnderReview) setRestrictedEventInfo(restrictedScreenData.isUnderReview);
    if (isSubscriptionCancelled && !isDeletedWorkspace)
      setRestrictedEventInfo(restrictedScreenData.isSubscriptionCancelled);
    if (isSubscriptionExpired) setRestrictedEventInfo(restrictedScreenData.isSubscriptionExpired);
    return () => {
      setRestrictedEventInfo(null);
    };
  }, [
    isDeletedWorkspace,
    isSubscriptionCancelled,
    isSubscriptionExpired,
    isUnderReview,
    isUserSuspended,
  ]);

  return { restrictedEventInfo };
};
