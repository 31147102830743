import { createContext, useState, useEffect, useReducer, useMemo, useRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import { OperationVariables, QueryLazyOptions, useReactiveVar } from '@apollo/client';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import useLocalStorage from 'hooks/useLocalStorage';
import useAuthContext from 'hooks/useAuthContext';
import { isNetworkAvailable } from 'services/apollo/reactiveVars';
import { useFcm } from 'components/pages/authentication/login/useFcm';

import { deviceConfig } from '../config/deviceSetup';
import { deviceEvent, ACTIONS } from '../constants';
import twilioReducers from './twilioReducers';
import { useHandlers } from './useHandlers';
import { useTwilioQuery } from './useTwilioQuery';
import { InitialStateType, LiveListeningProps, OutgoingProps } from '../types';

interface ITwilioContext {
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
  handleDeviceOutgoing: (params: OutgoingProps) => void;
  handleLiveListening: (params: LiveListeningProps) => void;
  deviceInstance: Device | null;
  deviceStatus: string;
  loadingVoiceToken: boolean;
  getVoiceToken: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
}

export const TwilioContext = createContext<ITwilioContext>({} as any);

const TwilioProvider = ({ children }: { children: React.ReactNode }) => {
  const { registerDevice } = useFcm();
  const initialState = {
    direction: '',
    showPhoneWidget: false,
    callInProgress: false,
    callEnded: false,
    status: 'initiated',
    showDialer: false,
    salesDialerWidget: false,
    outgoingCallParams: {},
    connection: {},
    prevConnection: {},
  };
  const [deviceInstance, setDeviceInstance] = useState<Device | null>(null);
  const [deviceStatus, setDeviceStatus] = useState('loading');
  const [voicetoken] = useLocalStorage('_voice-tk', null);
  const { activeWorkspaceId } = useAuthContext();
  const stateRef: any = useRef();
  const { getVoiceToken, data, loadingVoiceToken } = useTwilioQuery({
    deviceInstance,
    setDeviceInstance,
    setDeviceStatus,
  });
  const [state, dispatch] = useReducer(twilioReducers, initialState);
  const handler = useHandlers({
    dispatch,
    deviceInstance,
    state,
    setDeviceStatus,
  });
  stateRef.current = deviceInstance;
  const internetConnection = useReactiveVar(isNetworkAvailable);

  const unregisterDevice = () => {
    if (stateRef.current?.state === 'registered') {
      console.info('Unregister device');
      /** Unregister the Device to the Twilio backend, disallowing it to receive calls. */
      stateRef.current?.unregister();
      stateRef.current?.disconnectAll();
    }
    console.info('Destroy device');
    /** Destroy the Device, freeing references to be garbage collected. */
    stateRef.current?.disconnectAll();
    stateRef.current?.destroy();
  };

  useEffect(() => {
    if (internetConnection) {
      const voiceTkDecoded = voicetoken && jwtDecode<JwtPayload>(voicetoken);
      const tokenExpiryTime = voiceTkDecoded?.exp * 1000;
      // Note: token expiry time is checked before 120000 ms
      if (tokenExpiryTime && tokenExpiryTime - 120000 < Date.now()) {
        // REFETCH TWILIO VOICE TOKEN HERE IF INTERNET CONNECTION IS BACK AND PREVIOUS TOKEN WAS EXPIRED
        getVoiceToken({});
        return;
      }
      setDeviceStatus('online');
      return;
    }
    setDeviceStatus('offline');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetConnection]);

  useEffect(() => {
    if (
      deviceInstance &&
      deviceInstance?.state !== 'destroyed' &&
      data?.getVoiceToken?.data?.voiceToken
    ) {
      deviceInstance.updateOptions(deviceConfig);
      if (deviceInstance?.state === 'unregistered') {
        deviceInstance.register();
      }
      deviceInstance.on(deviceEvent.REGISTERED, handler.handleDeviceReady);
      deviceInstance.on(deviceEvent.REGISTERING, handler.handleRegisteringDevice);
      deviceInstance.on(deviceEvent.UNREGISTERED, handler.handleDeviceOffline);
      deviceInstance.on(deviceEvent.ERROR, handler.handleDeviceError);
      deviceInstance.on(deviceEvent.INCOMING, handler.handleDeviceIncoming);
      deviceInstance.on('tokenWillExpire', () => {
        handler.handleTokenWillExpire(getVoiceToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInstance]);

  useEffect(() => {
    if (activeWorkspaceId !== '') {
      getVoiceToken({});
      registerDevice();
    }
    return () => {
      unregisterDevice();
      setDeviceInstance(null);
      setDeviceStatus('loading');
      dispatch({
        type: ACTIONS.CLOSE_PHONE_WIDGET,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspaceId]);

  const value = useMemo(() => {
    return {
      state,
      dispatch,
      deviceInstance,
      deviceStatus,
      handleDeviceOutgoing: handler.handleDeviceOutgoing,
      handleLiveListening: handler.handleLiveListening,
      getVoiceToken,
      loadingVoiceToken,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, deviceInstance, deviceStatus, loadingVoiceToken]);

  return <TwilioContext.Provider value={value}>{children}</TwilioContext.Provider>;
};

export default TwilioProvider;
