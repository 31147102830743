import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { Workspace } from 'generated/graphql';
import { WORKSPACES } from 'graphql/foundation';
import { GET_USER_PROFILE } from 'graphql/user';
import { isNetworkAvailable, userOnlineStatus } from 'services/apollo/reactiveVars';
import { useWorkspaceSelector } from 'components/organisms/workspace';
import useAuthContext from 'hooks/useAuthContext';

export function useWorkspacesQuery() {
  const { i18n } = useTranslation();
  const internetAvailable = useReactiveVar(isNetworkAvailable);
  const { selectWorkspace } = useWorkspaceSelector();
  const { activeWorkspaceId } = useAuthContext();

  const [loadWorkspaces, { data: workspaceData }] = useLazyQuery(WORKSPACES, {
    fetchPolicy: 'cache-and-network',
  });

  const [loadUserProfile, { data }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      const { defaultLanguage, stayOnline, defaultWorkspace } = response?.profile?.data || {};
      i18n.changeLanguage(defaultLanguage);
      userOnlineStatus(stayOnline);
      if (defaultWorkspace !== activeWorkspaceId) {
        const activeWorkspace = workspaceData?.workspaces?.data?.find(
          (workspace: Workspace) =>
            workspace.id === defaultWorkspace && workspace.status === 'Active',
        );
        if (activeWorkspace) {
          const { id, memberId } = activeWorkspace;
          selectWorkspace({ workspaceId: id, memberId });
        }
      }
    },
  });

  useEffect(() => {
    if (internetAvailable) loadUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserProfile, internetAvailable]);

  useEffect(() => {
    loadWorkspaces();
  }, [loadWorkspaces]);

  const profileData = useMemo(() => data?.profile?.data ?? {}, [data]);
  return {
    profileData,
    workspaceData,
  };
}
