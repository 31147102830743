import { useHistory } from 'react-router-dom';

import * as path from 'constants/routes';
import useAuthContext from 'hooks/useAuthContext';
import * as S from '../Styles';

const PaypalNotification = () => {
  const history = useHistory();
  const { userRole } = useAuthContext();

  const handlePurchase = () => {
    history.push(`${path.SETTINGS}${path?.PLAN_SUBS}`);
  };

  return (
    <>
      <S.AlertWrapper className='z-20'>
        <S.CustomAlert
          message={
            <>
              <p data-cy='no-number-title' className='hidden lg:inline-block'>
                Due to unavailability of <span className='font-bold'>PayPal</span>, kindly update
                your payment method for a seamless checkout.
                {userRole !== 'Member' && (
                  <button
                    data-cy='click-button'
                    type='button'
                    onClick={handlePurchase}
                    className='mr-2  border rounded '
                  >
                    Click Here
                  </button>
                )}
              </p>
              <p className='lg:hidden'>
                Due to unavailability of <span className='font-bold'>PayPal</span>, kindly update
                your payment method
                {userRole !== 'Member' && (
                  <button
                    data-cy='click-button'
                    type='button'
                    onClick={handlePurchase}
                    className='mr-2  border rounded '
                  >
                    Click Here
                  </button>
                )}
              </p>
            </>
          }
          type='info'
          banner
          className='has-bordered-btn'
        />
      </S.AlertWrapper>
    </>
  );
};

export default PaypalNotification;
