import { createContext, useMemo } from 'react';
import { useNavHelper } from './workspace-detail-sidebar/useNavHelper';

export const NavHelperContext = createContext({});

export const NavHelperProvider = ({ children }: any) => {
  const { chatSubscriptionData, activeWorkspaceData, loadingActiveWorkspace } = useNavHelper();

  const contextValue: any = useMemo(
    () => ({
      chatSubscriptionData,
      activeWorkspaceData,
      loadingActiveWorkspace,
    }),
    [chatSubscriptionData, activeWorkspaceData, loadingActiveWorkspace],
  );

  return <NavHelperContext.Provider value={contextValue}>{children}</NavHelperContext.Provider>;
};
