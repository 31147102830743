import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Drawer } from 'antd';
import { useReactiveVar } from '@apollo/client';

import useScreenType from 'hooks/useScreenType';
import { ContactProvider } from 'components/organisms/contact/ContactContext';
import { Contact as ContactDetail } from 'components/organisms/contact';
import { Button } from 'components/atoms';
import { UserAddIcon } from 'components/atoms/icon';
import { ContactBook } from 'components/atoms/vectors';
import { ImportContactModal } from 'components/organisms/contact/modals';
import { AddContactModal } from 'components/organisms';
import { queryParamVars } from 'hooks/useContactVars';
import * as S from './Styles';
import { HandlersContext } from './HandlersContext';
import TopBar from '../topbar/TopBar';
import { Header } from './Header';
import ContactList from './List';

export default function ContactTable() {
  const {
    counts,
    showContactDetailDrawer,
    toggleContactDetailDrawer,
    loading,
    called,
    hasContact,
  } = useContext<any>(HandlersContext);
  const { contactId } = useParams<Record<string, string | undefined>>();
  const qParams = useReactiveVar<any>(queryParamVars);

  const { isBigScreen, isMobile } = useScreenType();

  const NUMBER_OF_CONTACTS = counts;
  const DRAWER_WIDTH = isMobile ? 260 : 360;

  const [visibleAddContactModal, setVisibleAddContactModal] = useState<boolean>(false);
  const [visibleImportContactModal, setVisibleImportContactModal] = useState(false);
  const toggleAddContactModal = () => setVisibleAddContactModal(prevState => !prevState);
  const toggleImportContactModal = () => setVisibleImportContactModal(prevState => !prevState);

  if (!loading && called && !hasContact && !qParams?.search?.value) {
    return (
      <>
        <TopBar numberOfContacts={0} />
        <S.EmptyContact className='h-full w-full flex'>
          <div className='m-auto'>
            <div className='mx-auto h-50 w-50 bg-info-100 rounded-full flex-center justify-center mb-8'>
              <ContactBook />
            </div>
            <div className='text-center w-100'>
              <h4
                className='text-2xl text-gray-700 leading-9 font-bold mb-3'
                data-cy='nocontact-title'
              >
                No Contacts
              </h4>
              <p className='text-base leading-6 text-gray' data-cy='nocontact-desc'>
                No existing contacts. Add your first contact here to start a conversation or{' '}
                <button
                  data-cy='bulk-upload-text-btn'
                  className='text-info'
                  type='button'
                  onClick={toggleImportContactModal}
                >
                  bulk upload
                </button>{' '}
                as a csv
              </p>
              <Button
                variant='success'
                className='mx-auto mt-10'
                onClick={toggleAddContactModal}
                size='large'
                icon={<UserAddIcon />}
                data-cy='contacts-add-button'
              >
                Add New Contact
              </Button>
            </div>
          </div>
        </S.EmptyContact>
        {visibleAddContactModal && (
          <AddContactModal
            isModalOpen={visibleAddContactModal}
            onCancel={toggleAddContactModal}
            onModalCancel={toggleAddContactModal}
            showAdditionalFields
          />
        )}
        <ImportContactModal
          isModalOpen={visibleImportContactModal}
          onCancel={toggleImportContactModal}
          onModalCancel={toggleImportContactModal}
        />
      </>
    );
  }

  return (
    <div className='flex w-full h-full '>
      <div className='w-full shadow-right overflow-auto'>
        <TopBar numberOfContacts={NUMBER_OF_CONTACTS} />
        <div style={{ overflow: 'auto', height: 'calc(100% - 65px)' }}>
          <Header />
          <ContactList />
        </div>
      </div>
      <ContactProvider>
        {contactId &&
          (isBigScreen ? (
            <ContactDetail />
          ) : (
            <Drawer
              width={DRAWER_WIDTH}
              placement='right'
              visible={showContactDetailDrawer}
              onClose={toggleContactDetailDrawer}
              closable={false}
            >
              <ContactDetail />
            </Drawer>
          ))}
      </ContactProvider>
    </div>
  );
}
