import { useContext } from 'react';
import { AuthContext } from 'contexts/auth/AuthContext';

export default function useAuthContext() {
  const state = useContext(AuthContext);
  if (!state) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return state;
}
