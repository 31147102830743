import { cache } from 'services/apollo';

const useUpdateCache = () => {
  const activeWorkspaceId = sessionStorage.getItem('_activeWorkspaceId');

  const updateCreditCache = (creditAmount: number) => {
    cache.modify({
      id: cache.identify({ id: activeWorkspaceId, __typename: 'Workspace' }),
      fields: {
        plan(existingPlanRef) {
          const updatedPlan = {
            ...existingPlanRef,
            currentCredit: creditAmount,
          };

          return updatedPlan;
        },
      },
    });
  };
  return {
    updateCreditCache,
  };
};

export default useUpdateCache;
