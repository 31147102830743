import { useHistory } from 'react-router-dom';

import useScreenType from 'hooks/useScreenType';
import useAuthContext from 'hooks/useAuthContext';
import * as path from 'constants/routes';
import * as S from '../Styles';

const GracePeriodNotification = () => {
  const { isTablet, isMobile } = useScreenType();
  const history = useHistory();
  const { userRole, remainingGracePeriod } = useAuthContext();
  const remainingGracePeriodDays =
    remainingGracePeriod === 1 ? ' 1 day' : `${remainingGracePeriod || ''} days`;

  const goToPlan = () => history.push(`${path.SETTINGS}${path.PLAN_SUBS}`);

  const renderMessageContent = () => (
    <>
      <p className='hidden lg:inline-block'>
        Attention Needed: Subscription Expired. Renew your subscription within
        <span className='font-bold'> {remainingGracePeriodDays}</span> to avoid service
        interruption.
        {userRole !== 'Member' && (
          <button type='button' onClick={goToPlan} className='mr-4'>
            Renew Subscription
          </button>
        )}
      </p>

      <p className='lg:hidden'>
        Subscription Expired. Renew within
        <span className='font-bold'> {remainingGracePeriodDays}</span>
        {userRole !== 'Member' && (
          <button type='button' onClick={goToPlan} className='mr-2'>
            Renew
          </button>
        )}
      </p>
    </>
  );

  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={remainingGracePeriod ? renderMessageContent() : ''}
        type='error'
        banner
        className={`has-bordered-btn ${isTablet || isMobile ? 'alert-sm' : ''}`}
      />
    </S.AlertWrapper>
  );
};

export default GracePeriodNotification;
