import { Radio, RadioChangeEvent } from 'antd';
import { useHistory } from 'react-router-dom';

import { Icon } from 'components/atoms';
import * as path from 'constants/routes';
import Voip from 'components/atoms/icon/Voip';
import { NonVoip } from 'components/atoms/icon';
import * as S from './Styles';

export const SelectNumberType = (props: any) => {
  const history = useHistory();
  const { next, onModalCancel } = props || {};
  const handleVoip = () => {
    history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`);
    onModalCancel?.();
  };
  const handleNonVoip = () => {
    next();
  };
  const onAccountTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 'non-voip') {
      handleNonVoip();
    }
    if (value === 'voip') {
      handleVoip();
    }
  };
  const accountTypeRadioOptions = [
    {
      icon: (
        <span className='icon'>
          <Voip />
        </span>
      ),
      label: 'VOIP',
      desc: 'Internet-based calling and messaging.',
      value: 'voip',
    },
    {
      icon: (
        <span className='icon'>
          <NonVoip />
        </span>
      ),
      label: 'Non-VOIP (USA)',
      desc: 'Number based on physical SIM.',
      value: 'non-voip',
    },
  ];
  return (
    <>
      <div className='select-number-modal'>
        <Icon name='purchase-number' />
        <h5 data-cy='tab-pane-add-button-modal-title'>Purchase Number</h5>
        <p data-cy='tab-pane-add-button-modal-desc'>Choose a type of number</p>
        <S.RadioGroupWrapper data-cy='account-setup-card' className='w-full'>
          <Radio.Group defaultValue='a' buttonStyle='solid'>
            {accountTypeRadioOptions.map((option: any) => (
              <Radio.Button
                key={option.value}
                value={option.value}
                className='flex'
                onChange={onAccountTypeChange}
              >
                {option.icon}
                <div className='d-flex'>
                  <span className='flex-item text-15 text-gray-700 mb-1.5 font-medium'>
                    {option.label}
                  </span>
                  <span className='flex-item text-13 text-gray'>{option.desc}</span>
                </div>
              </Radio.Button>
            ))}
          </Radio.Group>
        </S.RadioGroupWrapper>
      </div>
    </>
  );
};
