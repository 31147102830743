import { memo, useEffect, useState } from 'react';

import FallbackSpinner from 'components/atoms/spinner/Spinner';
import useAuthContext from 'hooks/useAuthContext';

import { useMakeCall } from './useMakeCall';
import NumbersNotFound from './number-not-found/NumberNotFound';
import WidgetHeader from './widget-header';
import WidgetBody from './widget-body';
import RestrictedScreens from './restricted-screens/RestrictedScreens';
import { restrictedScreenData } from './constants';

const Dialer = () => {
  const [restrictedEventInfo, setRestrictedEventInfo] = useState<any>(null);
  const {
    channels,
    loadingChannels,
    makeCall,
    selectedChannel,
    handleNumberSelect,
  } = useMakeCall();

  const {
    isDeletedWorkspace,
    isSubscriptionCancelled,
    isSubscriptionExpired,
    isUserSuspended,
    isUnderReview,
  } = useAuthContext();

  useEffect(() => {
    if (isUserSuspended) setRestrictedEventInfo(restrictedScreenData.isUserSuspended);
    if (isDeletedWorkspace) setRestrictedEventInfo(restrictedScreenData.isDeletedWorkspace);
    if (isUnderReview) setRestrictedEventInfo(restrictedScreenData.isUnderReview);
    if (isSubscriptionCancelled && !isDeletedWorkspace)
      setRestrictedEventInfo(restrictedScreenData.isSubscriptionCancelled);
    if (isSubscriptionExpired) setRestrictedEventInfo(restrictedScreenData.isSubscriptionExpired);
    return () => {
      setRestrictedEventInfo(null);
    };
  }, [
    isDeletedWorkspace,
    isSubscriptionCancelled,
    isSubscriptionExpired,
    isUnderReview,
    isUserSuspended,
  ]);

  if (restrictedEventInfo) {
    return (
      <>
        <WidgetHeader />
        <RestrictedScreens data={restrictedEventInfo} />
      </>
    );
  }

  return (
    <>
      <WidgetHeader />
      {loadingChannels ? (
        <FallbackSpinner />
      ) : (
        <>
          {channels?.length ? (
            <WidgetBody
              makeCall={makeCall}
              channels={channels}
              selectedChannel={selectedChannel}
              handleNumberSelect={handleNumberSelect}
            />
          ) : (
            <NumbersNotFound />
          )}
        </>
      )}
    </>
  );
};

export default memo(Dialer);
