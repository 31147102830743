import { InMemoryCache } from '@apollo/client';
import { persistCache, SessionStorageWrapper } from 'apollo3-cache-persist';
import { relayStylePagination } from '@apollo/client/utilities';
// import { FieldPolicy } from '@apollo/client/cache';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        nonVoipNumbersRequests: relayStylePagination(),
        purchaseSupportData: relayStylePagination(),
        portingSupportData: relayStylePagination(),
        enterpriseSupportData: relayStylePagination(),
        allUsers: relayStylePagination(),
        getAllWorkspaces: relayStylePagination(),
        workspaceReviews: relayStylePagination(),
        allEmailOtp: relayStylePagination(),
        creditLogs: relayStylePagination(),
        activityLogs: relayStylePagination(),
        incompleteSignups: relayStylePagination(),
        completeSignups: relayStylePagination(),
        getAllSpamWorkspaces: relayStylePagination(),
        campaignAnalytics: relayStylePagination(),
        allLogs: relayStylePagination(),
        workspaceActivities: relayStylePagination(),
      },
    },
    ConversationNode: {
      fields: {
        createdAt: {
          read(data) {
            return data || new Date().toISOString();
          },
        },
      },
    },
    /**
     * If we don't  have unique id in response.
     * we can set it through keFields.
     * @param UserProfile ->Typename
     * @param keyFields -> array of unique fields
     * @param email ->unique field
     */
    UserProfile: {
      keyFields: ['email'],
    },
    PhoneNumber: {
      keyFields: ['dialingNumber'],
    },
    PendingNumber: {
      keyFields: ['number'],
    },
  },
});

persistCache({
  cache: cache as any,
  storage: new SessionStorageWrapper(window.sessionStorage),
  trigger: 'write',
  debug: false,
});

export default cache;
