export default function OfferIcon() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.2203 6.29985L10.2003 3.27985C9.567 2.64652 8.69367 2.30652 7.80034 2.35318L4.46698 2.51318C3.13364 2.57318 2.07364 3.63318 2.00698 4.95985L1.84698 8.29318C1.80698 9.18652 2.14031 10.0598 2.77364 10.6932L5.79364 13.7132C7.03367 14.9532 9.047 14.9532 10.2937 13.7132L13.2203 10.7865C14.467 9.55318 14.467 7.53985 13.2203 6.29985ZM6.33364 8.75318C5.27364 8.75318 4.41364 7.89318 4.41364 6.83318C4.41364 5.77318 5.27364 4.91318 6.33364 4.91318C7.39367 4.91318 8.25367 5.77318 8.25367 6.83318C8.25367 7.89318 7.39367 8.75318 6.33364 8.75318Z'
        fill='#E07408'
      />
    </svg>
  );
}
