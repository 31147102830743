import { createContext, useMemo } from 'react';
import { useLoggedInMember as useAuth } from 'hooks/useLoggedInMember';

type IAuthContext = {
  accessToken: string;
  loggedInMemberInfo: any;
  loggedInMemberId: string;
  activeWorkspaceId?: string;
  planRemainingDays?: number;
  isSubscriptionCancelled?: boolean;
  isSubscriptionExpired?: boolean;
  userAuthId: string;
  isAlertBarVisible?: boolean;
  isUserSuspended?: boolean;
  isEmailVerifiedUser?: boolean;
  isUnderReview?: boolean;
  userEmail?: string;
  numberSubscription?: any;
  isAutoRenewFailed?: boolean;
  isNumberSubscriptionExpired?: boolean;
  isKYCVerified?: boolean;
  userRole?: string;
  remainingGracePeriod?: any; // TODO: need to update type
  isDeletedWorkspace?: boolean;
  isOnGracePeriod?: boolean;
  isPaypalDefaultCard?: boolean;
  username?: string;
};

const AuthContext = createContext<IAuthContext>({
  accessToken: '',
  loggedInMemberInfo: undefined,
  loggedInMemberId: '',
  activeWorkspaceId: '',
  planRemainingDays: 0,
  isSubscriptionCancelled: false,
  isSubscriptionExpired: false,
  userAuthId: '',
  isAlertBarVisible: false,
  isUserSuspended: false,
  isEmailVerifiedUser: false,
  isUnderReview: false,
  userEmail: '',
  numberSubscription: {},
  isAutoRenewFailed: false,
  isNumberSubscriptionExpired: false,
  isKYCVerified: false,
  userRole: '',
});

function AuthProvider({ children }: any) {
  const {
    accessToken,
    loggedInMemberInfo,
    loggedInMemberId,
    activeWorkspaceId,
    planRemainingDays,
    isSubscriptionCancelled,
    userAuthId,
    isSubscriptionExpired,
    isAlertBarVisible,
    isUserSuspended,
    isEmailVerifiedUser,
    isUnderReview,
    userEmail,
    numberSubscription,
    isAutoRenewFailed,
    isNumberSubscriptionExpired,
    isKYCVerified,
    userRole,
    remainingGracePeriod,
    isDeletedWorkspace,
    isOnGracePeriod,
    isPaypalDefaultCard,
    username,
    user,
    tokens,
  } = useAuth();

  const contextValue = useMemo(
    () => ({
      accessToken,
      loggedInMemberInfo,
      loggedInMemberId,
      activeWorkspaceId,
      planRemainingDays,
      isSubscriptionCancelled,
      isSubscriptionExpired,
      userAuthId,
      isAlertBarVisible,
      isUserSuspended,
      isEmailVerifiedUser,
      isUnderReview,
      userEmail,
      numberSubscription,
      isAutoRenewFailed,
      isNumberSubscriptionExpired,
      isKYCVerified,
      userRole,
      remainingGracePeriod,
      isDeletedWorkspace,
      isOnGracePeriod,
      isPaypalDefaultCard,
      username,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeWorkspaceId, loggedInMemberInfo, user, tokens],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
